.form-login{
    width: 450px;
    padding: 10px;
}

.form-login input{
    margin-bottom: 10px;    
}

.background-login{
    object-fit: cover;
    width: 60%;
    height: 60vh;
    object-position: left;
}