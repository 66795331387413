.react-modal-content{
    position: relative; 
    border-radius: 0px;
    outline: none;
    width: 100%;
    height: 75%;
    overflow: auto;
}
.react-modal-close{
    padding-top: 3%;
    padding-right: 8%;
}

.print-button-container{
    display: inline-block;
}



